import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { color } from '@configs/utilities'
import Layout from '@components/layouts/default/Layout'
import HeroSectionV9 from '@sections/home/HeroSectionV9'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import ReadySection from '@sections/common/ReadySection'
import TrustedBySection from '@sections/home/TrustedBySection'
import RatingSection from '@sections/home/RatingSection'
import OtherFeaturesSection from '@sections/home/OtherFeaturesSection'
import FeaturesTextImageSection from '@sections/home/FeaturesTextImageSection'
import VideoReviewSection from '@sections/home/VideoReviewSection'
import TemplatesCardsSection from '@sections/home/TemplatesCardsSection'
import { generateAppLink } from '@configs/helpers'
const Home = ({ intl }) => {
  return (
    <Layout pageName="home4">
      <HeroSectionV9 />
      <RatingSection
        sectionPaddingSm="10px 0 10px 0"
        sectionPaddingMd="10px 0 10px 0"
        sectionPaddingXl="30px 0 30px 0"
      />
      <TrustedBySection />
      <FeaturesTextImageSection />
      <AdvertisementSection
        componentBackground={color.blueDark}
        titleText={intl.formatMessage({ id: 'miscellaneous.readyToStartYourEntrepreneurialJourney' })}
        subtitleText={intl.formatMessage({ id: 'home.businessPlanning.subtitle' })}
        // to="/pricing"
        external
        href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: 'home.businessPlanning.button' })}
      />
      <TemplatesCardsSection />
      <OtherFeaturesSection />
      <TestimonialsSection />
      <VideoReviewSection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.videoReviewSection.title' })}
        description={intl.formatMessage({ id: 'home.videoReviewSection.description' })}
      />
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        buttonText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.buttonText' })}
        // to="/pricing"
        external
        href={generateAppLink('signup', intl.locale)}
        explain
        explainText={intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' })}
      />
    </Layout>
  )
}

export default injectIntl(Home)
